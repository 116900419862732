import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#7485ff', // #E53935
            secondary: '#e1e5ff', // #FFCDD2
            accent: '#FF6A9C', // #3F51B5
            success: '#00CA7C',//'#21d8bf',
            info:'#2196F3',
            error:'#e91e63',
            warning:'#FB8C00',
            tertiary: "#13D7BC",
            quaternary: "#3e4554"
          },
        },
      },
    lang: {
        current:'es'
    }
});

export default vuetify;