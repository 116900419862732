import Vue from 'vue'
import axios from './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import VueRouter from 'vue-router';

Vue.config.productionTip = false

var vue = new Vue({
  vuetify,
  router,
  axios,
  store,
  render: h => h(App),
  data: {
    drawer: null,
    showPregnancyTool: false,
    indexConsultaTool: null,
    dataConsultaTool: null,
    system: null,
    sbApp: {msg:'',show:false,color:'info'},
    gettingStarted: false,
    editProfile: false,
    openCV: false,
  },
  methods: {
    appMessage:function(p,c,time){
      if(this.sbApp.show)this.sbApp.show=false;
      this.$nextTick(()=>this.sbApp={msg:p,color:c,show:true,timeout:time});
    },
    showGettingStarted(f){this.gettingStarted=f},
    subirFoto(){this.gettingStarted=false;this.editProfile=true;},
    abrirCV(){this.gettingStarted=false;this.openCV=true;},
  },
  computed: {
    directorio: function (){ return `${process.env.VUE_APP_PM_URL}/directorio`},
    privacidad: function(){return `${process.env.VUE_APP_PM_URL}/aviso-privacidad.html`}
  }
});

window.axios.interceptors.request.use(function (config) {
  if (sessionStorage['mh-session-id']) {
    config.headers['mh-session-id'] = sessionStorage['mh-session-id'];
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

vue.axios.interceptors.response.use(function (response) {
  return response;
}, function (err) {
    let msg = (err.response.data&&err.response.data.message?err.response.data.message:err.response.statusText);
    switch(err.response.status){
        case 401: // The request requires HTTP authentication.
            sessionStorage['mh-session-id'] = null;
            store.commit('setUser', null);
            localStorage.mhSessionId = null;
            alert('Error 401 de autentificación');
            vue.$router.replace({ name: 'Login' });
            break;
        case 402: // Payment required (according to HttpServletResponse)
            if(vue.$route.name!=='NotFound') {
                vue.$router.replace({ name: 'NotFound', params:{expired:true}});
            }
            break;
        case 403: // The server understood the request but refused to fulfill it.
            vue.$router.replace({ name: 'NotFound' });
            break;
        case 406:
            alert( ' (' + err.response.status+') ' + err.response.data );
            break;
        case 500: // Error inside the HTTP server which prevented it from fulfilling the request.
            alert('Error interno, comuníque al proveedor: ' + msg);
            break;
        default:
            alert('La petición que hizo no es correcta: '+ msg+' (' + err.response.status+')' );
    }
  return Promise.reject(err);
});

vue.$mount('#app')