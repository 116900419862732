import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from '@/views/NotFound.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Login',
    component:()=>import(/* webpackChunkName:"login"*/ '@/views/Login.vue')
  },
  {
    path: '/signup',
    name: 'SignUp',
    component:()=>import(/* webpackChunkName:"signup"*/ '@/views/SignUp.vue')
  },
  {
    path: '/confirm/:u',
    name: 'Confirm',
    component:()=>import(/* webpackChunkName:"confirm"*/ '@/views/ConfirmarRegistro.vue')
  },
  {
    path: '/home',
    name:'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Agenda.vue')
  },
  {
    path:'/pacientes',
    name:'Pacientes',
    component: () => import(/* webpackChunkName: "pacientes" */ '@/views/Pacientes.vue')
  },
  {
    path:'/configura-receta',
    name:'ConfiguraReceta',
    component: () => import(/* webpackChunkName: "receta" */ '@/views/ConfiguraReceta.vue')
  },
  {
    path:'/consulta/:c/:p',
    name:'Consulta',
    component:()=>import(/* webpackChunkName: "consulta" */ '@/views/Consulta.vue'),
  },
  {
    path: '/consultorios',
    name:'Consultorios',
    component: () => import(/* webpackChunkName: "consultorios" */ '@/views/Consultorios.vue')
  },
  {
    path: '/uso-sistema',
    name:'Estadisticas',
    component: () => import(/* webpackChunkName: "estadisticas" */ '@/views/Estadisticas.vue')
  },
  {
    path: '/feedback',
    name:'Feedback',
    component: () => import(/* webpackChunkName: "feedback" */ '@/views/Feedback.vue')
  },
  {
    path: '/BlogList',
    name: 'BlogList',
    component: () => import(/* webpackChunkName: "bloglist" */ '@/views/BlogList.vue')
  },
  {
    path: '/BlogEdit/:id',
    name: 'BlogEdit',
    component: () => import(/* webpackChunkName: "blogedit" */ '@/views/BlogEdit.vue')
  },
  {
    path:'/not-found',
    name:'NotFound',
    component:NotFound,
    props: true
  },
  { path: "*", redirect: '/'  },
  {
    path: '/asistentes',
    name: 'Asistentes',
    component: () => import(/* webpackChunkName: "asistentes" */ '@/views/Asistentes.vue')
  },
  {
    path: '/preferencias',
    name: 'preferencias',
    component: () => import('@/views/Preferencias.vue')
  }, 
  {
    path: '/reportes',
    name: 'Reportes',
    component: () => import(/* webpackChunkName: "reportes" */ '@/views/Reportes.vue')
  },
  {
    path: '/precios',
    name: 'Precios',
    component:()=>import(/* webpackChunkName:"precios"*/ '@/views/Precios.vue')
  },
  {
    path: '/system',
    name: 'System',
    component:()=>import(/* webpackChunkName:"system"*/ '@/views/System.vue')
  },
  {
    path: '/comprar',
    name: 'Comprar',
    component:()=>import(/* webpackChunkName:"compras"*/ '@/views/Precios.vue')
  },
  {
    path: '/planes-de-compra',
    name: 'PlanesSistema',
    component:()=>import(/* webpackChunkName:"planes"*/ '@/views/PlanesSistema.vue')
  }, 
  {
    path: '/public-restablecer/step-1',
    name: 'Restablecer',
    component:()=>import(/* webpackChunkName:"step1"*/ '@/views/RestablecerContrasena.vue')
  },
  {
    path: '/public-restablecer/step-2/:uuid',
    name: 'Step2',
    component:()=>import(/* webpackChunkName:"step2"*/ '@/views/RestablecerContrasena.vue')
  },
  {
    path: '/public-restablecer/step-3/:c/:u',
    name: 'Step3',
    component:()=>import(/* webpackChunkName:"step3"*/ '@/views/RestablecerContrasena.vue')
  },
  {
    path: '/borrados',
    name: 'BorradosSistema',
    component:()=>import(/* webpackChunkName:"borrados"*/ '@/views/BorradosSistema.vue')
  },   
  {
    path: '/exportador',
    name: 'Exportador',
    component:()=>import(/* webpackChunkName:"exportador"*/ '@/views/Exportador.vue')
  },
  {
    path: '/importador',
    name: 'Importador',
    component:()=>import(/* webpackChunkName:"importador"*/ '@/views/Importador.vue')
  },
  {
    path: '/fusionador',
    name: 'Fusionador',
    component:()=>import(/* webpackChunkName:"fusionador"*/ '@/views/Fusionador.vue')
  },
  {
    path: '/home-analytics',
    name: 'HomeAnalytics',
    component:()=>import(/* webpackChunkName:"home-analytics"*/ '@/views/HomeAnalytics.vue')
  },
  {
    path: '/contactanos',
    name:'Contactanos',
    component: () => import(/* webpackChunkName: "contactanos" */ '@/views/Contactanos.vue')
  },
  {
    path: '/social',
    name: 'Social',
    component: () => import(/* webpackChunkName: "Social" */ '@/views/Social.vue')
  },
]

const router = new VueRouter({
  routes
})
// router.beforeEach((to, from, next) => {
//   if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
//   else next()
// })
export default router
