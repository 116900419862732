<template>
  <v-app>
    <v-app-bar class="primary" dense dark max-height="48px" id="app" app>
      <!--v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon-->
      <v-app-bar-nav-icon>
        <v-img
          src="@/assets/practica-medica-blanco.png"
          max-height="28px"
          max-width="28px"
          @click="checkIfGoesToLogin"
        ></v-img>
      </v-app-bar-nav-icon>
      <!-- 
      <v-btn
        icon
        @click="
          drawer = true && $route.name != 'Login' && $route.name != 'Precios'
        "
      >
        <v-badge color="red accent-4" overlap :content="appCnt" :value="appCnt">
          <v-icon v-if="userInfo">mdi-menu</v-icon>
          <v-img
            v-else
            src="@/assets/favicon.png"
            max-height="28px"
            max-width="28px"
          ></v-img>
        </v-badge>
      </v-btn>
      -->
      <v-toolbar-title><b>Práctica médica</b></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="go2blog" title="Blog de noticias">
        <v-icon
          @click="logout"
          v-if="
            this.$route.name == 'Login' ||
            this.$route.name == 'Index' ||
            this.$route.name == 'Precios'
          "
          >mdi-post-outline</v-icon
        >
      </v-btn>
      <v-btn icon>
        <v-icon
          @click="logout"
          v-if="
            this.$route.name !== 'Login' &&
            this.$route.name !== 'Index' &&
            this.$route.name !== 'Precios'
          "
          >mdi-logout</v-icon
        >
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      permanent
      mini-variant
      v-if="$route.name != 'Login' && this.userInfo"
      class="ma-0 white"
    >
      <v-list nav dense>
        <v-list-item
          v-if="isMed || isAsi"
          @click="showVigencia = true"
          title="Mi cuenta"
        >
          <v-list-item-icon>
            <v-icon>mdi-shield-account-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-left">Vigencia</v-list-item-title>
        </v-list-item>
        <v-divider class="mb-1"></v-divider>
        <v-list-item-group v-model="group">
          <template v-if="isMed || isAsi">
            <v-list-item
              key="home"
              :to="{ name: 'Home' }"
              title="Agenda (citas y eventos)"
              :class="vliClass('Agenda')"
            >
              <v-list-item-icon>
                <v-icon>mdi-calendar-clock</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-left">Agenda</v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="{ name: 'Pacientes' }"
              title="Registra ó busca pacientes"
              :class="vliClass('Agenda')"
            >
              <v-list-item-icon>
                <v-badge
                  color="green"
                  overlap
                  :content="vliBdg('Agenda')"
                  :value="vliBdg('Agenda')"
                >
                  <v-icon>mdi-account</v-icon>
                </v-badge>
              </v-list-item-icon>
              <v-list-item-title class="text-left">Pacientes</v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="{ name: 'Estadisticas' }"
              title="Estadísticas de mi práctica médica"
            >
              <v-list-item-icon>
                <v-icon>mdi-chart-bar-stacked</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-left"
                >Mis estadísticas</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              :to="{ name: 'Feedback' }"
              title="Comentarios y soporte"
            >
              <v-list-item-icon>
                <v-icon>mdi-account-tie-voice</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-left"
                >Comentarios y soporte</v-list-item-title
              >
            </v-list-item>
            <!--        
          <v-list-item :to="{ name: 'Reportes' }">
            <v-list-item-icon>
              <v-icon>mdi-clipboard-list-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-left">Reportes</v-list-item-title>
          </v-list-item>
          -->
            <v-list-item
              @click="floatingOptions"
              title="Configuración"
              v-if="isMed"
            >
              <v-list-item-icon>
                <v-icon>mdi-tune-vertical</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-left"
                >Configuración</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="comoEmpezar" title="Cómo empezar" v-if="isMed">
              <v-list-item-icon>
                <v-icon>mdi-information-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-left"
                >Cómo empezar</v-list-item-title
              >
            </v-list-item>
          </template>
          <template v-else-if="isBlogger">
            <v-list-item :to="{ name: 'BlogList' }" title="Blog">
              <v-list-item-icon>
                <v-icon>mdi-post-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-left">Blog</v-list-item-title>
            </v-list-item>
          </template>
          <template v-else-if="isSystem">
            <v-list-item key="system" :to="{ name: 'System' }" title="Usuarios">
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-left">Sistema</v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="{ name: 'BorradosSistema' }"
              title="Usuarios borrados"
            >
              <v-list-item-icon>
                <v-icon>mdi-account-minus</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-left">Borrados</v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="{ name: 'PlanesSistema' }"
              title="Lista de planes"
            >
              <v-list-item-icon>
                <v-icon>mdi-credit-card-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-left">Planes</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'HomeAnalytics' }" title="Analítica">
              <v-list-item-icon>
                <v-icon>mdi-home-analytics</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-left">Analítica</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item :to="{ name: 'BlogList' }" title="Blog">
              <v-list-item-icon>
                <v-icon>mdi-post-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-left">Blog</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item :to="{ name: 'Social' }">
              <v-list-item-icon><v-icon>mdi-facebook</v-icon></v-list-item-icon>
              <v-list-item-title class="text-left"
                >Integrar Fb</v-list-item-title
              >
            </v-list-item>
          </template>
          <v-divider></v-divider>

          <v-list-item @click="logout" title="Salir">
            <v-list-item-icon>
              <v-icon title="Salir">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-left">Salir</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- -->
    <v-main class="pt-10">
      <v-container fluid>
        <router-view @message="message"></router-view>
      </v-container>
    </v-main>
    <v-menu
      v-if="floatopts && floatopts.show"
      :x="floatopts.clientX"
      :y="floatopts.clientY + 20"
      v-model="floatopts.show"
      offset-y
      :close-delay="5000"
      :close-on-content-click="false"
      class="white"
      style="max-width: 190px"
    >
      <v-list flat dense style="max-width: 190px">
        <v-subheader>Configuración</v-subheader>
        <template v-for="(item, index) in configuracion">
          <v-list-group
            v-if="item.children"
            :key="index"
            color="primary"
            sub-group
            no-action
            :value="true"
            style="max-width: 190px"
          >
            <v-list-item
              slot="activator"
              class="pl-0 mr-0 pr-0"
              max-width="50px"
            >
              <v-list-item-title class="text-left">
                {{ item.title }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item dense class="pl-5 pr-0 mr-0">
              <template>
                <div class="d-flex justify-space-between">
                  <v-btn
                    v-for="(child, i) in item.children"
                    :key="`${index}_${i}`"
                    :title="child.tooltip ? child.tooltip : child.title"
                    icon
                    @click="onMenuItemSelected(child)"
                    class="mr-1"
                  >
                    <v-icon v-text="child.icon"></v-icon>
                  </v-btn>
                </div>
              </template>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            @click="onMenuItemSelected(item)"
            class="pl-2"
            :key="index"
            :title="item.tooltip ? item.tooltip : item.title"
            max-width="100px;"
          >
            <v-list-item-action v-if="item.icon" class="ml-2 mr-4">
              <v-btn
                text
                class="text-caption font-weight-medium"
                style="text-transform: none"
              >
                <v-icon v-text="item.icon">{{ item.icon }}</v-icon>
                {{ item.title }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
    <v-menu v-if="userInfo && !isSystem" v-model="showVigencia">
      <v-card max-width="280">
        <v-card-title
          class="secondary py-3"
          @click="onIndexChanged(2)"
          title="Clic para editar tu nombre y correo"
          style="cursor: pointer"
        >
          <v-btn icon class="white" @click="onIndexChanged(2)" v-if="!avtChain">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-avatar
            color="primary ligthten-2"
            size="50"
            v-if="avtChain"
            class="mr-2"
          >
            <v-img :src="`data:image/png;base64,${avtChain}`"></v-img>
          </v-avatar>
          <span class="ml-3">
            {{ userInfo.nombres }}
          </span>
        </v-card-title>
        <v-card-subtitle
          class="my-2 font-weight-medium"
          @click="onIndexChanged(2)"
          style="cursor: pointer"
          >{{ userInfo.correo }}</v-card-subtitle
        >
        <v-card-text>
          <template v-if="isMed">
            <b>Vigencia:</b>
            <div>{{ userInfo.vigencia }}</div>
            <v-progress-linear
              indeterminate
              v-if="gettingExtra"
            ></v-progress-linear>
            <div v-if="extra" class="mt-2">
              <b title="Vigencias sobre el directorio">Directorio:</b>
              <div
                v-if="extra.aparecerEnDirectorio"
                :color="extra.novalid1 ? 'text-grey' : ''"
              >
                Para aparecer: {{ extra.aparecerEnDirectorio }}
              </div>
              <div v-else>Para aparecer: -</div>
              <div
                v-if="extra.agendarEnDirectorio"
                :color="extra.novalid2 ? 'text-grey' : ''"
              >
                Para agendar: {{ extra.agendarEnDirectorio }}
              </div>
              <div v-else>Para agendar: -</div>
            </div>
            <div v-else-if="!gettingExtra" class="mt-3 mx-5 caption">
              No cuentas con un plan para aparecer en el
              <a :href="this.$root.directorio">directorio</a>
            </div>
          </template>
          <template v-else> Asistente </template>
        </v-card-text>
        <v-card-actions v-if="isMed" class="mt-2">
          <v-row>
            <v-col cols="6">
              <v-btn small color="primary" text @click="onIndexChanged(1)">
                <v-icon>mdi-card-account-details-star</v-icon>
                Cédula
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                small
                color="primary"
                text
                @click="onIndexChanged(3)"
                title="Mis datos profesionales"
              >
                <v-icon class="mr-1">mdi-card-account-details</v-icon>
                CV
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                small
                color="accent"
                text
                @click="onIndexChanged(0)"
                title="Historial de mis compras"
              >
                <v-icon>mdi-cart</v-icon>
                Historial
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn small color="accent" text @click="comprar">
                <v-icon>mdi-credit-card</v-icon>
                Comprar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-dialog
      v-if="userInfo && dialog"
      v-model="dialog"
      :width="dialogItem && dialogItem.width ? dialogItem.width : '360px'"
      style="height: 420px; min-height: 420px; max-width: 412px"
      scrollable
    >
      <v-card>
        <template v-if="dialogItem">
          <v-card-title>{{ dialogItem.title }}</v-card-title>
          <v-card-text>
            <suscripciones
              v-if="dialogItem.key == 'his'"
              @message="message"
            ></suscripciones>
            <cedula
              v-else-if="dialogItem.key == 'ced'"
              @message="message"
              @close="dialog = false"
            ></cedula>
            <editar-usuario
              v-else-if="dialogItem.key == 'edit'"
              :no-title="true"
              @message="message"
              @close="dialog = false"
              @user-saved="iniciarSesion"
            ></editar-usuario>
            <mi-cv
              v-else-if="dialogItem.key == 'cv'"
              @message="message"
              @close="dialog = false"
            >
            </mi-cv>
          </v-card-text>
        </template>
        <!--
        <v-card-actions class="justify-end">
          <v-btn depressed @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>-->
      </v-card>
    </v-dialog>

    <v-snackbar v-model="sb.show" :color="sb.color" :timeout="sb.to" top>
      {{ sb.msg }}
      <v-btn color="white" text @click="sb.show = false">Enterado</v-btn>
    </v-snackbar>
    <v-snackbar
      :color="globalSnack.color"
      v-model="globalSnack.show"
      :timeout="globalSnack.timeout || timeout"
    >
      {{ globalSnack.msg }}
      <v-btn color="black" text @click="globalSnack.show = false"
        >Cerrar
      </v-btn>
    </v-snackbar>
    <v-footer
      absolute
      class="mt-2 font-weight-medium"
      color="primary"
      v-if="$route.name == 'Index'"
    >
      Copyright © {{ new Date().getFullYear() }} Datateam Consulting, S.A. de
      C.V. & Práctica Médica - ManikHub
    </v-footer>
    <!-- v-snackbar v-model="sb" top :timeout="6000">El sistema funciona con Firefox, Chrome u Opera. Tu navegdor no está soportado</v-snackbar-->
    <wizard v-if="showGettingStarted"></wizard>
  </v-app>
</template>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.animate-bg {
  animation-name: gradient0;
  animation-delay: 0.5s;
  animation-duration: 7s;
}
@keyframes gradient0 {
  from {
    background-color: rgba(100, 149, 237, 0.6);
  }
  to {
    background-color: rgba(240, 248, 255, 0.6);
  }
  // f->CornflowerBlue  (100, 149, 237)
  // t->AliceBlue (240, 248, 255)
}
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

Verde: #12acb8
Azul: #07446a
Gris claro: #e6e6e6
Gris Oscuro: #666666 Fuentes

MiMédicoManik
-------------
Verde: #5CB65C
Azul: #057ABE

 */
@media print {
  div.v-navigation-drawer__content,
  div.v-navigation-drawer__border,
  nav,
  header > div.v-toolbar__content > button {
    display: none;
    width: 0px;
    height: 0px;
  }
  div.v-toolbar__content {
    z-index: 0;
    box-shadow: none;
  }
  div.v-main__wrap {
    z-index: 500;
  }
}
</style>
<script>
const VALUES_BDG = { noVisitados: 0, preguntas: 0 };
let comps = [
  { key: "his", title: "Historial", width: "80%" },
  { key: "ced", title: "Cédula" },
  { key: "edit", title: "Editar" },
  { key: "cv", title: "Curriculum", width: "80%" },
];
export default {
  methods: {
    go2blog() {
      window.location.href = "/blog";
    },
    vliClass(t) {
      return this.optionBdg && this.optionBdg == t && this.drawer
        ? "animate-bg"
        : "";
    },
    vliBdg(t) {
      return this.optionBdg && this.optionBdg == t ? 1 : 0;
    },
    logout: function () {
      this.axios
        .get("/session/logout")
        .then(function () {})
        .catch(function (err) {})
        .then(
          function () {
            this.bye();
          }.bind(this)
        );
    },
    bye: function () {
      this.reset();
      this.$router.replace({ name: "Login" });
    },
    reset: function () {
      this.clearBdg();
      window.sessionStorage["mh-session-id"] = null;
      this.$store.commit("setUser", null);
      localStorage.mhSessionId = null;
      return null;
    },
    onIndexChanged: function (index) {
      this.dialogItem = comps[index];
      if (this.dialogItem) {
        this.dialog = true;
      }
    },
    comprar: function () {
      this.$router.replace({
        name: "Comprar",
      });
    },
    isBrowserCompatible: function () {
      // Opera 8.0+
      var isOpera =
        (!!window.opr && !!window.opr.addons) ||
        !!window.opera ||
        navigator.userAgent.indexOf(" OPR/") >= 0;

      // Firefox 1.0+
      var isFirefox = typeof InstallTrigger !== "undefined";

      // Safari 3.0+ "[object HTMLElementConstructor]"
      var isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(
          !window["safari"] ||
            (typeof safari !== "undefined" && eval("safari.pushNotification"))
        );

      // Chrome 1 - 79
      var isChrome =
        !!window.chrome &&
        (!!window.chrome.webstore || !!window.chrome.runtime);

      // Edge (based on chromium) detection
      var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;

      // Blink engine detection
      var isBlink = (isChrome || isOpera) && !!window.CSS;
      return isOpera || isFirefox || (isChrome && !isEdgeChromium && !isBlink);
    },
    iniciarSesion: async function () {
      const w = window.location.href;
      if (
        w.indexOf("login") > -1 ||
        w.indexOf("precios") > -1 ||
        w.indexOf("#/confirm/") > -1 ||
        w.indexOf("#/public-") > -1 ||
        w.endsWith("#/signup") ||
        w.indexOf("#/contactanos") > -1 ||
        w.endsWith("#/")
      )
        return;
      var noalive = true;
      try {
        const resp = await this.axios.get("/session/alive");
        if (resp.data) {
          delete resp.data["id"];
          this.$store.commit("setUser", resp.data);
          noalive = false;
          if (
            this.isSystem &&
            this.$router.currentRoute.name.indexOf("System") > -1 &&
            this.$router.currentRoute.name.indexOf("Sistema") > -1
          ) {
            this.$router.replace({ name: "System" });
          }
        }
      } catch (err) {
        if (err.response.status === 402) {
          return;
        }
        alert(
          err.response.status +
            ": " +
            (err.response.data && err.response.data.message
              ? err.response.data.message
              : err.response.statusText)
        );
      }

      if (noalive) {
        // No hay sesión válida, entonces redireccionar a index.html
        this.bye();
      } else {
        // Cuando haya sesión válida, entonces ejecutar acciones propias de sesión válida
        //setTimeout(() => this.getInfoForBadges(), 3000);
      }
    },
    getParameterByName(name, url = window.location.href) {
      name = name.replace(/[[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    onMenuItemSelected: function (item) {
      if (item && item.to) {
        let params = item.params ? item.params : {};
        if (this.$router.currentRoute.name == item.to) {
          this.$nextTick(() => {
            window.location.reload();
          });
        } else {
          this.$router.replace({ name: item.to, query: params });
        }
      }
      this.$nextTick(() => {
        this.floatopts = null;
      });
    },

    message: function (msg, level) {
      this.sb.color = level;
      this.sb.msg = msg;
      this.sb.show = true;
    },

    clearBdg: function () {
      this.inform = VALUES_BDG;
      this.optionBdg = null;
    },
    handle_fn_call: function (function_name) {
      this[function_name]();
    },
    getInformClass: function (key) {
      return key && this.inform[key] && this.inform[key] > 0
        ? "animate-bg"
        : "";
    },
    floatingOptions: function (e) {
      this.floatopts = null;
      this.$nextTick(() => {
        this.floatopts = e;
        this.floatopts.show = true;
      });
      //floatopts
    },
    checkIfGoesToLogin: function () {
      if (!this.userInfo) this.bye();
    },
    getExtra: function () {
      if (this.isMed) {
        this.gettingExtra = true;
        this.axios
          .get("/medico/sobre-vigencias")
          .then(({ data }) => {
            if (data && (data.aparecerEnDirectorio || data.agendarEnDirectorio))
              this.extra = data;
          })
          .catch(function (err) {})
          .then(() => {
            this.gettingExtra = false;
          });
      }
      this.getAvatar();
    },
    getAvatar() {
      this.axios
        .get(`/profile/avatar`)
        .then(({ data }) => (this.avtChain = data));
    },
    comoEmpezar() {
      this.$root.showGettingStarted(true);
    },
  },

  computed: {
    isSystem() {
      return this.userInfo.rol == 1;
    },
    isMed() {
      return this.userInfo.rol == 2;
    },
    isAsi() {
      return this.userInfo.rol == 4;
    },
    userInfo: function () {
      return this.$store.state.user;
    },
    avatar: function () {
      return null; //this.userInfo.avatar?`data:image/png;base64,${this.userInfo.avatar}`: `${process.env.VUE_APP_MMM_URL}/web/avatar?aii=${this.userInfo.uuid}`;
    },
    appCnt: function () {
      var cnt = this.optionBdg ? 1 : 0;
      Object.entries(this.inform).forEach(([, val]) => (cnt += val));
      return cnt;
    },
    rootDrawer: function () {
      return this.$root.drawer;
    },
    inboxBadge: function () {
      var cnt = 0;
      this.inboxItems.forEach(
        (e) =>
          (cnt += e.infKey && this.inform[e.infKey] ? this.inform[e.infKey] : 0)
      );
      return cnt;
    },
    isBlogger: function () {
      return this.userInfo.rol == 8;
    },
    globalSnack: function () {
      return this.$root.sbApp;
    },
    showGettingStarted: function () {
      return this.$root.gettingStarted;
    },
    r_editProfile: function () {
      return this.$root.editProfile;
    },
    r_openCV: function () {
      return this.$root.openCV;
    },
  },
  watch: {
    rootDrawer(v) {
      if (v != null) {
        this.optionBdg = v;
      } else {
        this.$root.drawer = null;
        this.optionBdg = null;
      }
    },
    drawer(v) {
      if (!v && this.optionBdg != null) this.optionBdg = null;
    },
    dialog(v) {
      if (!v) {
        this.dialogItem = null;
        this.$root.editProfile = false;
        this.$root.openCV = false;
      }
    },
    showVigencia(v) {
      if (v === true) {
        this.getExtra();
      } else {
        this.gettingExtra = false;
        this.extra = null;
      }
    },
    r_editProfile(v) {
      if (v) this.onIndexChanged(2);
    },
    r_openCV(v) {
      if (v) this.onIndexChanged(3);
    },
  },
  data: function () {
    return {
      sb: { show: false, msg: "", color: "success", to: 3000 },
      drawer: false,
      optionBdg: null,
      inform: { noVisitados: 0, preguntas: 0 },
      group: null,
      avtChain: null,
      configuracion: [
        {
          title: "Consultorios",
          icon: "mdi-hospital-building",
          to: "Consultorios",
          tooltip: "Administración de horarios",
        },
        {
          title: "Asistentes",
          icon: "mdi-account-multiple",
          to: "Asistentes",
          tooltip: "Registra tus asistentes",
        },
        {
          icon: "mdi-prescription",
          title: "Formato de receta",
          expand: false,
          to: "ConfiguraReceta",
        },
        {
          icon: "mdi-cogs",
          title: "Preferencias",
          to: "preferencias",
          tooltip: "Configura tus preferencias",
        },
        {
          icon: "mdi-folder-zip-outline",
          title: "Exportador",
          to: "Exportador",
          tooltip: "Exportador de expedientes clínicos",
        },
        {
          icon: "mdi-folder-upload-outline",
          title: "Importador",
          to: "Importador",
          tooltip: "Importador de expedientes clínicos",
        },
        {
          icon: "mdi-set-center",
          title: "Fusionador",
          to: "Fusionador",
          tooltip: "Fusiona pacientes repetidos",
        },
      ],
      expandInbox: false,
      configVal: false,
      floatopts: null,
      showVigencia: false,
      dialog: false,
      dialogItem: 0,
      gettingExtra: false,
      extra: null,
    };
  },
  components: {
    Suscripciones: () => import("@/components/Suscripciones"),
    Cedula: () => import("@/components/Cedula"),
    EditarUsuario: () => import("@/components/EditarUsuario"),
    MiCv: () => import("@/components/CV"),
    Wizard: () => import("@/components/GettingStarted"),
  },
  mounted() {
    if (!this.isBrowserCompatible) {
      this.message(
        "El sistema funciona con Firefox, Chrome u Opera. Tu navegdor no está soportado",
        "error"
      );
    }
    this.iniciarSesion(); // Cualquier otra operación debe estar dentro de esta función
  },
};
</script>
